import React, { useCallback, useMemo } from 'react';

import {
  // AudioIcon,
  // AutoRotateIcon,
  // ContactFormIcon,
  // ExpandIcon,
  FloorMapIcon,
  // FullScreenIcon,
  // GyroscopeIcon,
  // HelpIcon,
  MapFloorPlanIcon,
  // ShareIcon,
} from 'components/icons';
// import configs from 'configs';
// import { isMobile } from 'react-device-detect';
import { limitLetters } from 'utils/limitLetters';

export default function MobileSimpleLayoutIcons(props) {
  const {
    currentPano,
    // isAutoPlay,
    // color,
    // toggleHelpButton,
    featuredMedia,
    // isShowAudioIcon,
    // canFullscreen,
    // menuPosition,
    // handleFullScreen,
    // fullScreen,
    isShowMapFloorPlan,
    setIsActiveMap,
    isActiveMap,
    mapShowed,
    setOpenDropDown,
    openDropDown,
    handleSelectMap,
    scenes,
    floorMaps,
    // switchMode,
    handleUpdateRoute,
    onSelect,
    isSchoolLayout,
    // enableGyroscope,
    // handleSwitchControlMode,
    openMenu,
    isMenuGallery,
    isVerticalImage,
    mediaMap2D,
    setImageSize,
    isSimpleLayout,
  } = props;

  const defaultSceneIds = useMemo(
    () =>
      window.data.json.groups
        .filter((g) => g.defaultSceneId)
        .map((g) => g.defaultSceneId),
    []
  );

  const handleSwitchMap = useCallback(
    (item) => {
      handleSelectMap(item);
      if (item.objects) {
        const defaultObj = item.objects.find(
          (o) => defaultSceneIds.indexOf(o.id) !== -1
        );
        if (defaultObj) {
          const defaultScene =
            scenes.find((s) => s._id === defaultObj.id) || {};
          if (defaultScene?.groupId && defaultScene?.id) {
            onSelect(defaultScene.groupId, defaultScene.id);
          }
        }
      }
    },
    [handleSelectMap, onSelect, scenes, defaultSceneIds]
  );

  return (
    <div
      className={`icons ${
        openMenu && (isMenuGallery || isSchoolLayout) ? 'translateY' : ''
      }`}
    >
      {featuredMedia.map && (
        <div
          className="icons_icon icon-floor"
          onClick={() => handleUpdateRoute(featuredMedia.map.id)}
        >
          <FloorMapIcon />
        </div>
      )}

      {isShowMapFloorPlan && (
        <>
          <div
            className="icons_icon position-relative"
            onClick={() => setIsActiveMap(!isActiveMap)}
          >
            <MapFloorPlanIcon active={isActiveMap} />
          </div>
          <div
            className={`mapfloorplan_container ${
              isVerticalImage ? 'vertical-image' : ''
            } ${
              isActiveMap
                ? `open ${isVerticalImage ? 'open-vertical--image' : ''}`
                : ''
            }  ${isSimpleLayout ? 'simple-maplayout' : ''}`}
          >
            {mapShowed && (
              <>
                {floorMaps?.length > 1 && (
                  <div className="map_header">
                    <div
                      className="map_dropdown"
                      onClick={() => setOpenDropDown(!openDropDown)}
                    >
                      <span>{limitLetters(mapShowed.image.name, 15)}</span>
                      <i className="map_arrow down"></i>
                      {openDropDown && (
                        <div
                          className={`map_dropdown_options ${
                            openDropDown ? 'show' : ''
                          }`}
                        >
                          <ul className="dropdown_items">
                            {floorMaps?.map((item, index) => (
                              <li
                                onClick={() => handleSwitchMap(item)}
                                key={index}
                              >
                                {limitLetters(item.image.name, 30)}
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                    <div
                      className="icon"
                      onClick={() => handleUpdateRoute(mediaMap2D.id)}
                    >
                      {/* <ExpandIcon /> */}
                    </div>
                  </div>
                )}
                <div className="map_image">
                  <img
                    src={mapShowed.image.url}
                    alt={mapShowed.image.name}
                    onLoad={(e) =>
                      setImageSize({
                        width: e.target.naturalWidth,
                        height: e.target.naturalHeight,
                      })
                    }
                  />
                  {mapShowed.objects.map((viewpoint) => {
                    const currentPoint = viewpoint.id === currentPano._id;
                    const [left, top] = viewpoint.coordinates;
                    const leftInPercent = left * 100;
                    const topInPercent = top * 100;
                    const currentScene =
                      scenes.find((s) => s._id === viewpoint.id) || {};

                    return (
                      <React.Fragment key={viewpoint.id}>
                        <div
                          className={`viewpoint-container ${
                            currentPoint ? 'current-viewpoint' : 'viewpoint'
                          } `}
                          style={{
                            left: `${leftInPercent}%`,
                            top: `${topInPercent}%`,
                          }}
                          onClick={() =>
                            onSelect(currentScene.groupId, currentScene.id)
                          }
                        ></div>
                        {/* <p
                        className="scene-title"
                        style={{
                          left: `${leftInPercent}%`,
                          top: `${topInPercent}%`,
                        }}
                      >
                        {currentScene.title}
                      </p> */}
                        <div
                          className="circular-sector"
                          style={{
                            display: `${currentPoint ? 'block' : 'none'}`,
                            left: `${leftInPercent}%`,
                            top: `${topInPercent}%`,
                          }}
                        >
                          {/* <MinorSector {...props} /> */}
                        </div>
                      </React.Fragment>
                    );
                  })}
                </div>

                <div
                  className="mapfloorplan_close"
                  onClick={() => setIsActiveMap(false)}
                >
                  {/* <button className="mapfloorplan_close-btn">&times;</button> */}
                </div>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
}
