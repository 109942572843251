import {
  formatJsonImgUrl,
  formatOldJson,
  updateDefaultValues,
  updateScenesAndHotspots,
  updateMenuItemsWithScene,
  updateItemId,
  updateFeatureMedia,
  checkEnableBranding,
} from './jsonHelper';
import configs from '../configs';
import { combineTour } from './tourHelper';
import { convertObjectKeys } from './urlHelper';

export const loadJson = async (url) => {
  try {
    const response = await fetch(url + '?v=' + new Date().getTime());
    return await response.json();
  } catch (error) {
    window.logMessage('load.json.error:', error);
    return null;
  }
};

export const ensureData = (data) => {
  updateFeatureMedia(data);
  updateScenesAndHotspots(data);
  updateMenuItemsWithScene(data);
  updateItemId(data);
  updateDefaultValues(data);
  return data;
};

export const loadFromUrl = async (jsonUrl) => {
  const json = await loadJson(jsonUrl);
  if (json) {
    const newData = convertObjectKeys(json);
    const data = formatOldJson(formatJsonImgUrl(jsonUrl, newData));
    return ensureData(data);
  }
  return null;
};

export const loadTourDetail = async (tourId) => {
  const urlParams = new URLSearchParams(window.location.search);
  const sessionId = urlParams.get('sessionId');
  const localSessionId = localStorage.getItem('sessionId');
  if (sessionId) localStorage.setItem('sessionId', sessionId);

  const apiUrl =
    sessionId || localSessionId
      ? `${configs.apiUrl}Tours/${tourId}/detail?sessionId=${
          sessionId || localSessionId
        }`
      : `${configs.apiUrl}Tours/${tourId}/detail`;

  try {
    const response = await fetch(apiUrl);
    const data = await response.json();

    //remove sessionId from URL for security
    if (data.data === null) {
      const currentUrl = window.location.href;
      const updatedUrl = currentUrl.replace(/(\?|&)sessionId=[^&]*/, '');
      window.history.replaceState({}, document.title, updatedUrl);
    }

    return data.data;
  } catch (error) {
    window.logMessage('loadTourDetail.error', error);
    return null;
  }
};

export const loadTourJsons = async (tourId) => {
  try {
    const reqBody = {
      tours: [tourId],
    };
    if (configs.viewMode) {
      reqBody.isPublished = true;
    }
    const response = await fetch(`${configs.apiUrl}Tours/json`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(reqBody),
    });
    const { data } = await response.json();
    if (data && data.length) {
      return data;
    }
  } catch (error) {
    window.logMessage('loadTourJsons.error', error);
  }
  return [];
};

export const loadPreviewJson = async (tourId) => {
  const data = await loadTourJsons(tourId);
  if (data && data.length) {
    return data.pop();
  }
  return null;
};

export const loadPublishedJson = async (tourId) => {
  const data = await loadTourJsons(tourId);
  if (data && data.length) {
    return data.find((d) => d.isPublished) || null;
  }
  return null;
};

var parsePreloadJsonScript = (jsonStr) => {
  const jsonStrFormatted = jsonStr
    .replace(/__OPEN_TAG__/g, '<')
    .replace(/__CLOSE_TAG__/g, '>');
  return JSON.parse(jsonStrFormatted);
};

export const loadTourJson = async (tourAccessCode) => {
  const data = {
    tour: null,
    json: null,
  };

  const preloadEl = document.getElementById('vizion-data');

  if (preloadEl) {
    data.json = ensureData(parsePreloadJsonScript(preloadEl.textContent));
    data.tour = combineTour(data.json.tour, data.json);
    data.tour = checkEnableBranding(data.tour);
    window.data = data;

    return Promise.resolve(data);
  }

  try {
    const tour = await loadTourDetail(tourAccessCode);
    if (!tour?.id) return data;
    if (!tour.active) return { tour };
    const requestFunction = configs.viewMode
      ? loadPublishedJson
      : loadPreviewJson;
    const jsonRecord = await requestFunction(tour.id);

    window.logMessage('jsonRecord', jsonRecord);

    if (jsonRecord?.jsonUrl) {
      const json = await loadFromUrl(jsonRecord.jsonUrl);
      if (json) {
        json.tour.active = true;
        data.tour = combineTour(json.tour, json);
        data.tour = checkEnableBranding(data.tour, tour.subscriptionPlan);
        data.tour.subscriptionPlan = tour.subscriptionPlan;
        data.json = json;
      }
    }
  } catch (error) {
    window.logMessage('loadTourJson.error', error);
  }
  window.data = data;
  window.logMessage('window.data', data);
  return data;
};
